import { memo, useCallback, useEffect, useState, type ChangeEvent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { groupsCategories, groupsCategoryIds, type GroupsCategory } from '@orus.eu/activity'
import type { ActivityGroup } from '@orus.eu/backend/src/services/activity-grouping/types'
import type { DisplayedGroupsUi } from '@orus.eu/backend/src/views/activity-grouping-view'
import type { OperatingZone } from '@orus.eu/operating-zone'
import {
  Avatar,
  ChoiceGrid,
  RowButtonV2,
  RowContainerV2,
  RowV2DynamicButton,
  Text,
  TextField,
  colors,
  spacing,
  useCrash,
  useDebounce,
  type ChoiceGridItemAvatarProps,
} from '@orus.eu/pharaoh'
import { useSearch } from '@tanstack/react-router'
import { trpc } from '../../client'
import { sendMessage } from '../../lib/tracking/tracking'
import { LocalLoadingState } from '../molecules/local-loading-state'

type ActivitySearchFieldProps = {
  operatingZone: OperatingZone
  defaultActivityList?: DisplayedGroupsUi[]
  onActivitySelected: (activity: string) => void
  onActivityNotFound?: () => void
  onCategorySelected?: (category: GroupsCategory) => void
  autoFocus?: boolean
  defaultValue?: string | undefined
  filteredCategory?: GroupsCategory
  onCategoryChanged?: () => void
}

type ActivityNotFoundButtonProps = {
  onClick?: () => void
}

const ActivityNotFoundButton = memo<ActivityNotFoundButtonProps>(function ActivityNotFoundButton(props) {
  const { onClick } = props
  return onClick ? (
    <RowButtonV2
      primaryText="Je ne trouve pas mon activité"
      avatarLeft={<Avatar variant="contained" icon="circle-exclamation-regular" color={colors.blue[500]} size="50" />}
      onClick={onClick}
    />
  ) : undefined
})

const getActivityName = (activity: ActivityGroup): string => {
  return activity.groupName === 'Activité exclue' || activity.groupName === 'Autre activité'
    ? activity.name
    : activity.groupName
}

export const ActivitySearchField = memo<ActivitySearchFieldProps>(function ActivitySearchField(props) {
  const {
    operatingZone,
    defaultActivityList,
    defaultValue,
    onActivitySelected,
    onActivityNotFound,
    onCategorySelected,
    autoFocus,
    filteredCategory,
    onCategoryChanged,
  } = props

  const urlSearchParams = useSearch({ strict: false })
  const isEmbedInIframe = urlSearchParams.embedInIframe != null

  const [displayedActivities, setDisplayedActivities] = useState<ActivityGroup[]>([])
  const [value, setValue] = useState<string>('')
  const [debouncedValue] = useDebounce(value, 1000)
  const [loading, setLoading] = useState<boolean>(false)
  const crash = useCrash()

  const handleSelectedActivityChange = useCallback(
    (activity: ActivityGroup) => {
      setLoading(true)
      onActivitySelected(getActivityName(activity))
    },
    [onActivitySelected],
  )

  const handleSelectedCategoryChange = useCallback(
    (category: GroupsCategory) => {
      if (onCategorySelected) {
        setLoading(true)
        onCategorySelected(category)
      }
    },
    [onCategorySelected],
  )

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])

  useEffect(() => {
    if (defaultValue) setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    let cancelled = false
    ;(async () => {
      const list = await trpc.selfOnboarding.getMatchingActivities.query({ search: value, operatingZone })
      if (cancelled) return

      const visitedGroupNames = new Set<string>()
      const uniqueByGroupFilteredDisplayedActivities = list.filter((activity) => {
        if (visitedGroupNames.has(activity.groupName)) {
          return false
        }
        visitedGroupNames.add(activity.groupName)
        return true
      })
      setDisplayedActivities(uniqueByGroupFilteredDisplayedActivities)
    })().catch((err) => {
      if (cancelled) return
      crash({ type: 'unexpected-error', err })
    })

    return () => {
      cancelled = true
    }
  }, [value, crash, operatingZone])

  useEffect(() => {
    if (debouncedValue === null) return
    const trimedTypedQuery = debouncedValue.trim()
    if (trimedTypedQuery.length > 2) {
      sendMessage({
        event: 'activity_search_typing',
        typed_query: trimedTypedQuery,
      })
    }
  }, [debouncedValue])

  if (loading) {
    return <LocalLoadingState />
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[70]};
      `}
    >
      <TextField
        {...props}
        size="large"
        css={css`
          caret-color: ${colors.blue[500]};
          margin-top: ${isEmbedInIframe ? '2px' : '0px'};
        `}
        placeholder={
          filteredCategory ? groupsCategories[filteredCategory].placeHolder : activitySearchPlaceholder[operatingZone]
        }
        autoFocus={autoFocus || isEmbedInIframe}
        aria-label="search"
        onChange={handleInputChange}
        value={value}
      />
      {filteredCategory && onCategoryChanged ? (
        <div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: ${spacing[70]};
            `}
          >
            {value === '' ? <Text variant="body2">ou choisissez votre activité principale ici</Text> : <></>}
            <RowV2DynamicButton
              label={groupsCategories[filteredCategory].displayName}
              avatarProps={activitiesGroupsCategoriesAttributes[filteredCategory]}
              value={filteredCategory}
              ctaText="Changer"
              onItemClicked={onCategoryChanged}
            />
          </div>
          {value === '' ? (
            <RowContainerV2
              css={css`
                margin-top: ${spacing[70]};
              `}
            >
              {defaultActivityList && defaultActivityList.length > 0 ? (
                defaultActivityList.map((activity) => {
                  return (
                    <RowButtonV2
                      key={getActivityName(activity)}
                      onClick={() => handleSelectedActivityChange(activity)}
                      secondaryText={getActivityName(activity)}
                      tertiaryText={activity.description}
                    />
                  )
                })
              ) : (
                <></>
              )}
            </RowContainerV2>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {value === '' ? (
        onCategorySelected && operatingZoneSupportsCategories[operatingZone] ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: ${spacing[70]};
            `}
          >
            <Text variant="body2">ou choisissez votre domaine d&apos;activité principal</Text>
            <ActivitiesCategory onCategorySelected={handleSelectedCategoryChange} />
          </div>
        ) : (
          <></>
        )
      ) : (
        <ActivitiesRowContainer isEmbedInIframe={isEmbedInIframe}>
          {displayedActivities.length > 0 ? (
            displayedActivities.map((activity) => {
              return (
                <RowButtonV2
                  key={getActivityName(activity)}
                  onClick={() => handleSelectedActivityChange(activity)}
                  secondaryText={getActivityName(activity)}
                  tertiaryText={activity.description}
                />
              )
            })
          ) : (
            <></>
          )}
          <ActivityNotFoundButton onClick={onActivityNotFound} />
        </ActivitiesRowContainer>
      )}
    </div>
  )
})

type ActivitiesCategoryProps = {
  onCategorySelected: (category: GroupsCategory) => void
}

const ActivitiesCategory = memo<ActivitiesCategoryProps>(function ActivityNotFoundButton(props) {
  const { onCategorySelected } = props
  return (
    <div>
      <ChoiceGrid
        values={groupsCategoryIds}
        labels={categoriesLabels}
        onItemClicked={onCategorySelected}
        avatarProps={activitiesGroupsCategoriesAttributes}
      />
    </div>
  )
})

const categoriesLabels = Object.keys(groupsCategories).reduce(
  (result, item) => {
    const categoryKey = item as GroupsCategory
    result[categoryKey] = groupsCategories[categoryKey].displayName
    return result
  },
  {} as Record<GroupsCategory, string>,
)

const activitiesGroupsCategoriesAttributes: Record<GroupsCategory, ChoiceGridItemAvatarProps> = {
  construction: {
    icon: 'shovel-light',
    backgroundColor: 'periwinkle',
  },
  consultingServices: {
    icon: 'comment-light',
    backgroundColor: 'mindaro',
  },
  cultureMediaTourism: {
    icon: 'book-light',
    backgroundColor: 'peach',
  },
  restaurantsCoffee: {
    icon: 'user-chef-light',
    backgroundColor: 'jasmine',
  },
  retailCraft: {
    icon: 'bag-shopping-light',
    backgroundColor: 'mindaro',
  },
  securityTransport: {
    icon: 'shield-check-light',
    backgroundColor: 'jasmine',
  },
  tech: {
    icon: 'laptop-light',
    backgroundColor: 'sky',
  },
  wellnessBeauty: {
    icon: 'hand-holding-heart-light',
    backgroundColor: 'peach',
  },
}

const ActivitiesRowContainer = styled(RowContainerV2)<{ isEmbedInIframe: boolean }>`
  max-height: ${(props) => (props.isEmbedInIframe ? '600px' : 'none')};
  overflow: hidden ${(props) => (props.isEmbedInIframe ? 'auto' : 'hidden')};
`

const operatingZoneSupportsCategories: Record<OperatingZone, boolean> = {
  fr: true,
  es: false,
}

const activitySearchPlaceholder: Record<OperatingZone, string> = {
  fr: 'Exemple : restaurant, psychologue, développeur...',
  es: 'Ejemplo: desarrollo, consultores de calidad...',
}
