import { css } from '@emotion/react'
import { Autocomplete, TextField, createFilterOptions, type FilterOptionsState, type SxProps } from '@mui/material'
import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { secondaryColor, spacing } from '@orus.eu/pharaoh'
import { ProductBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { memo, useCallback, useMemo, useState, type FunctionComponent, type SyntheticEvent } from 'react'
import type { CompleteGroupLoader } from '../../lib/group-mapping-util'
import { useApi } from '../../lib/use-api/use-api'

export type ActivityGroupSearchFieldProps = {
  operatingZone: OperatingZone
  groupLoader: CompleteGroupLoader
  onChange: (group?: string) => void
  errorText?: string
  sx?: SxProps
  disabled?: boolean
}

const defaultFilterOptions = createFilterOptions<GroupData>()
const filterOptions = (options: GroupData[], state: FilterOptionsState<GroupData>) => {
  return defaultFilterOptions(options, state).slice(0, 20)
}

export const ActivityGroupSearchField: FunctionComponent<ActivityGroupSearchFieldProps> = memo(
  function ActivityGroupSearchField({ operatingZone, onChange, errorText, sx, groupLoader, disabled }) {
    const activitiesResponse = useApi(groupLoader.getGroups)
    const activityList = useMemo(
      () =>
        activitiesResponse.ready
          ? activitiesResponse.data.filter((group) => group.operatingZone === operatingZone)
          : emptyActivityList,
      [activitiesResponse.data, activitiesResponse.ready, operatingZone],
    )
    const [activityGroup, setActivityGroup] = useState<GroupData | null>(null)

    const handleSelectedActivityChange = useCallback(
      (_event: SyntheticEvent<Element, Event>, group: GroupData | null) => {
        setActivityGroup(group)
        onChange(group ? group.name : undefined)
      },
      [onChange],
    )

    const getOptionLabel = useCallback((group: GroupData) => group.name, [])

    return (
      <Autocomplete
        sx={sx}
        disabled={!activitiesResponse.ready}
        options={activityList}
        onChange={handleSelectedActivityChange}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        value={activityGroup}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            autoFocus
            label="Rechercher mon activité"
            helperText={errorText}
            error={!!errorText}
          />
        )}
        renderOption={(props, group, { inputValue }) => {
          const groupName = group.name
          const matches = match(groupName, inputValue, { insideWords: true })
          const parts = parse(groupName, matches)

          return (
            <li
              {...props}
              css={css`
                display: flex;
                gap: ${spacing[20]};
              `}
            >
              <div
                css={css`
                  flex-grow: 1;
                `}
              >
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? secondaryColor : undefined,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
              {group.products.map((product) => (
                <ProductBadge key={product} product={product} />
              ))}
            </li>
          )
        }}
      />
    )
  },
)

const emptyActivityList: GroupData[] = []
